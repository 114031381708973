// ** Initial State
const initialState = {
  data: [],
  total: 1,
  from: 0,
  to: 0,
  params: {},
  selectedData: null
}

const members = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_MEMBER':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        from: action.from,
        to: action.to,
      }
    case 'GET_MEMBER':
      return { ...state, selectedData: action.selectedData }
    case 'ADD_MEMBER':
      return { ...state }
    case 'EDIT_MEMBER':
      return { ...state }
    case 'DELETE_MEMBER':
      return { ...state }
    default:
      return { ...state }
  }
}
export default members
