// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

import divisions from '@src/views/master/division/store/reducer'
import users from '@src/views/master/user/store/reducer'
import members from '@src/views/master/member/store/reducer'
import memberVerifications from '@src/views/master/member-verification/store/reducer'
import incomingMails from '@src/views/incoming/mail/store/reducer'
import categories from '@src/views/information/category/store/reducer'
import categoriesReference from '@src/views/reference/category/store/reducer'
import createInformation from '@src/views/information/create/store/reducer'
import createReference from '@src/views/reference/create/store/reducer'
import survivors from '@src/views/mitigation/survivor/store/reducer'
import deads from '@src/views/mitigation/dead/store/reducer'
import vaccinations from '@src/views/mitigation/vaccine/store/reducer'
import strs from '@src/views/str/admin/store/reducer'
import contributions from '@src/views/contribution/store/reducer'
import reference from '@src/views/reference/central/store/reducer'
import medicolegals from '@src/views/reporting/medicolegal/store/reducer'
import colleagues from '@src/views/reporting/colleague/store/reducer'
import mooc from '@src/views/mooc/store/reducer'
import pit from '@src/views/pit/store/reducer'
import inalarm from '@src/views/in-alarm/store/reducer'
import proposals from '@src/views/proposal/store/reducer'
import memberVerificationDatas from '@src/views/master/member-verification-data/store/reducer'
import activities from '@src/views/activity/store/reducer'
import forums from '@src/views/forum/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  members,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  divisions,
  incomingMails,
  categories,
  createInformation,
  createReference,
  reference,
  categoriesReference,
  survivors,
  deads,
  vaccinations,
  strs,
  contributions,
  medicolegals,
  colleagues,
  memberVerifications,
  mooc,
  pit,
  inalarm,
  proposals,
  memberVerificationDatas,
  activities,
  forums
})

export default rootReducer
