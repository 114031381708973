// ** Initial State
const initialState = {
  data: [],
  total: 1,
  from: 0,
  to: 0,
  params: {},
  selectedData: null
}

const contributions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_CONTRIBUTION':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        from: action.from,
        to: action.to,
      }
      
      case 'GET_DATA_CONTRIBUTION_ADMIN':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          from: action.from,
          to: action.to,
        }

      case 'GET_DATA_REPORT_KAS':
        return {
          ...state,
          data: action.data,
          params: action.params,
        }

      case 'GET_DATA_REPORT_PAYMENT':
        return {
          ...state,
          data: action.data,
          params: action.params,
        }

      case 'GET_DATA_REPORT_MEMBER':
        return {
          ...state,
          data: action.data,
          params: action.params,
        }

      case 'GET_DATA_CONTRIBUTION_ADMIN_COLLECTIVE':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        from: action.from,
        to: action.to,
      }

      case 'GET_DATA_DASHBOARD_TOTAL_DIVISION':
        return {
          ...state,
          data: action.data,
          params: action.params,
        }
        
      case 'GET_DATA_CONTRIBUTION_ADMIN_STATISTIC':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params,
          from: action.from,
          to: action.to,
        }
    case 'GET_CONTRIBUTION':
      return { ...state, selectedData: action.selectedData }
    case 'ADD_CONTRIBUTION':
      return { ...state }
    case 'EDIT_CONTRIBUTION':
      return { ...state }
    case 'DELETE_CONTRIBUTION':
      return { ...state }
    default:
      return { ...state }
  }
}
export default contributions
