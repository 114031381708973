const initialState = {
  products: [],
  highlights: [],
  wishlist: [],
  cart: [],
  productDetail: {},
  params: {},
  totalProducts: 0
}

const referenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCTS':
      return { 
        ...state, 
        products: action.data.data,
        params: action.params, 
        totalProducts: action.data.total 
    }
    case 'GET_HIGHLIGHTS':
      return { 
        ...state, 
        highlights: action.data,
    }
    case 'GET_WISHLIST':
      return { ...state, wishlist: action.data.products }
    case 'DELETE_WISHLIST_ITEM':
      return { ...state }
    case 'GET_CART':
      return { ...state, cart: action.data.products }
    case 'DELETE_CART_ITEM':
      return { ...state }
    case 'ADD_TO_WISHLIST':
      return { ...state }
    case 'ADD_TO_CART':
      return { ...state }
    case 'GET_PRODUCT':
      return { ...state, productDetail: action.data }
    default:
      return state
  }
}

export default referenceReducer
