// ** Initial State
const initialState = {
  data: [],
  total: 1,
  from: 0,
  to: 0,
  params: {},
  selectedData: null,
};

const incomingMails = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_INCOMING_MAIL":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        from: action.from,
        to: action.to,
      };
    case "GET_INCOMING_MAIL":
      return { ...state, selectedData: action.selectedData };
    case "ADD_INCOMING_MAIL":
      return { ...state };
    case "EDIT_INCOMING_MAIL":
      return { ...state };
    case "DELETE_INCOMING_MAIL":
      return { ...state };
    default:
      return { ...state };
  }
};
export default incomingMails;
