import CryptoJS from 'crypto-js';

const SECRET = 'EMOPPOGI123';

export const encrypt = (value) => {
    return CryptoJS.AES.encrypt(value, SECRET)
};

export const decrypt = (value) => {
  if (value) {
    const bytes = CryptoJS.AES.decrypt(value, SECRET);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  
  return value;
};
