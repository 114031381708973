// ** Initial State
const initialState = {
  data: [],
  total: 1,
  from: 0,
  to: 0,
  params: {},
  selectedData: null,
  data2: [],
  total2: 1,
  from2: 0,
  t2o: 0,
  params2: {},
}

const colleagues = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA_COLLEAGUE':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        from: action.from,
        to: action.to,
      }
    case 'GET_DATA_COLLEAGUE2':
      return {
        ...state,
        data2: action.data,
        total2: action.totalPages,
        params2: action.params,
        from2: action.from,
        to2: action.to,
      }
    case 'GET_COLLEAGUE':
      return { ...state, selectedData: action.selectedData }
    case 'ADD_COLLEAGUE':
      return { ...state }
    case 'EDIT_COLLEAGUE':
      return { ...state }
    case 'DELETE_COLLEAGUE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default colleagues
